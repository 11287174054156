"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
require("./styles.css");
const THREE = __importStar(require("three"));
const CAMERA_HEIGHT = 10;
const mouseOnEdge = () => {
    const w = Math.abs(mouse.x / window.innerWidth);
    const h = Math.abs(mouse.y / window.innerHeight);
    return 0.01 > w || w > 0.99 || 0.01 > h || h > 0.99;
};
// rotate color
let color = 0x6ac28c; // green;
function rotateColor() {
    switch (color) {
        case 0xf38fb0: // pink
            color = 0x6ac28c; // green
            break;
        case 0x6ac28c: // green
            color = 0x394ca0; // blue
            break;
        case 0x394ca0: // blue
            color = 0xe6483f; // red
            break;
        case 0xe6483f: // red
            color = 0xf59c60; // orange
            break;
        case 0xf59c60: // orange
            color = 0xf38fb0; // pink
            break;
    }
}
// renderer
const renderer = new THREE.WebGLRenderer();
// camera
const camera = new THREE.OrthographicCamera(0, window.innerWidth, 0, -window.innerHeight, 0.1, 10);
camera.position.z = CAMERA_HEIGHT;
// scene
const scene = new THREE.Scene();
scene.background = new THREE.Color(0xf38fb0);
scene.add(camera);
// render
const render = () => {
    // draw circles
    if (!mouseOnEdge()) {
        const size = Math.max(window.innerWidth, window.innerHeight) * 0.2;
        const geometry = new THREE.CircleGeometry(size, 100);
        const material = new THREE.MeshBasicMaterial({
            color,
        });
        const circle = new THREE.Mesh(geometry, material);
        circle.position.set(mouse.x, mouse.y, mouse.z);
        scene.add(circle);
        // setTimeout(() => scene.remove(circle), 100);
    }
    renderer.render(scene, camera);
};
// mouse
let mouse = new THREE.Vector3(0, 0, 0);
function handleMouseMove(e) {
    var _a, _b;
    if (e.type.includes("touch")) {
        const evt = e;
        const touch = ((_a = evt.touches) === null || _a === void 0 ? void 0 : _a[0]) || ((_b = evt.changedTouches) === null || _b === void 0 ? void 0 : _b[0]);
        mouse.x = touch.pageX;
        mouse.y = -touch.pageY;
    }
    else {
        const evt = e;
        mouse.x = evt.clientX;
        mouse.y = -evt.clientY;
    }
    mouse.z = 0;
    render();
}
function onWindowResize() {
    // set renderer
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    // set camera
    camera.right = window.innerWidth;
    camera.bottom = -window.innerHeight;
    camera.updateProjectionMatrix();
    render();
}
// init
document.body.appendChild(renderer.domElement);
const toggleDialog = (name) => {
    const vis = document.getElementById(name).style.visibility;
    if (vis === "hidden") {
        document.getElementById(name).style.visibility = "visible";
    }
    else {
        document.getElementById(name).style.visibility = "hidden";
    }
};
["email", "chat"].map((i) => Array.from(document.getElementsByClassName(`${i}-dialog-toggle`)).map((e) => (e.onclick = (evt) => toggleDialog(`${i}-dialog`))));
onWindowResize();
window.addEventListener("mouseover", () => mouseOnEdge() && rotateColor());
window.addEventListener("touchend", rotateColor);
window.addEventListener("mousemove", handleMouseMove);
window.addEventListener("touchmove", handleMouseMove);
window.addEventListener("resize", onWindowResize);
window.addEventListener("load", onWindowResize);
